<template>
  <v-container fluid>


    <card :title="$t('models.variation.selves')">
      <template v-slot:actions>
        <v-btn @click="importDialog = true" color="primary" small>
          <v-icon small left>fa fa-file-excel</v-icon>
          Import a config
        </v-btn>
      </template>

      <grid-view ref="grid" :headers="headers" endpoint="variation?expand=sector,country" model="variation">
        <template v-slot:item._actions="{ item }">
          <v-btn @click="exportModel(item)" icon small>
            <v-icon x-small>fa fa-download</v-icon>
          </v-btn>
          <v-btn @click="deleteModel(item)" icon small>
            <v-icon x-small>fa fa-trash</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.sector="{ item }">
          {{ translate(item.sector.name) }}
        </template>
        <template v-slot:item.country="{ item }">
          {{ translate(item.country.name) }}
        </template>
      </grid-view>
    </card>

    <!-- import dialog -->
    <v-dialog v-model="importDialog" max-width="600">
      <import-config v-if="importDialog" @done="configImported()"/>
    </v-dialog>

  </v-container>
</template>

<script>
import {Component, Vue, Watch} from "vue-property-decorator";
import formatter from '@/mixins/formatter';
import importConfig from '@/pages/variation/Import';
import {downloadToDisk} from "@/utils/download";
import GridView from "@/components/GridView";
import Card from "@/components/Card";
import {setPageTitle} from "@/utils/meta";

@Component({components: {Card, GridView, importConfig}, mixins: [formatter]})
export default class VariationIndex extends Vue {

  model = null;
  importDialog = false;
  downloading = false;

  created() {
    setPageTitle(this.$i18n.t('models.variation.selves'));
  }

  get headers() {
    return [
      {text: '', value: '_actions',},
      {text: this.$i18n.t('models.variation.sector'), value: 'sector',},
      {text: this.$i18n.t('models.variation.country'), value: 'country',},
      {text: this.$i18n.t('models.variation.version'), value: 'version',},
      {text: this.$i18n.t('models.variation.alpha'), value: 'alpha', width: 1},
      {text: this.$i18n.t('models.variation.beta'), value: 'beta', width: 1},
      {text: this.$i18n.t('models.variation.gamma'), value: 'gamma', width: 1},
    ];
  }

  viewModel(model) {
    this.$router.push(`/variation/${model.id}`);
  }

  exportModel(model) {
    this.downloading = true;
    this.$api.post(`variation/${model.id}/export`, {}, {
      responseType: 'blob'
    }).then(response => {
      this.downloading = false;
      downloadToDisk(response);
    });
  }

  deleteModel(model) {
    this.$root.$confirm.open('Delete model', 'Are you sure you want to delete this model ?', {}).then((confirm) => {
      if (confirm) {
        this.$api.delete(`variation/${model.id}`).then(() => {
          this.$refs.grid.getModels();
        }).catch(() => {
          alert("Unable to remove model");
        });
      }
    });
  }

  configImported() {
    this.importDialog = false;
    this.$refs.grid.getModels();
  }

  @Watch("$store.state.configUploads")
  onValueChanged(n, o) {
    if (n.length !== o.length) {
      this.$refs.grid.getModels();
    }
  }

}
</script>
